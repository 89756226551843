import React from 'react';
import BackgroundImage from "../components/image";
import styled from 'styled-components';

const Wrapper = styled.div`

.couple{
    display:flex;
}
.image{
    width:50vw;
}
.imageFull{
    width:100vw;
}
@media (max-width:1024px){
    .image{
        width:100vw;
        display:inline-block;
    }
}


@media (max-width:768px){
    .couple{
        flex-direction: column;
    }
     
    .imageFull{height:100vw};
    .imageFull .gatsby-image-wrapper {
    height: 100%;
}
}
`
const mockup = (props) => {

    const imageList = {
        insights: {
            filename1: "insightslivingroom.jpg",
            filename2: "insightspool.jpg",
            filename3: "insightssofa.jpg"
        },
        roman: {
            filename1: "romaneditionlivingroom.jpg",
            filename2: "romaneditionbedroom.jpg",
            filename3: "romaneditionterrazzo.jpg"
        },
        homepage1: {
            filename1: "guccieyes.jpg",
            filename2: "romaneditionbedroom.jpg",
        }
    }

    return <Wrapper>
        <div className="couple">
            <div className="image">
                <BackgroundImage filename={imageList[props.project].filename1} />
            </div>
            <div className="image">
                <BackgroundImage filename={imageList[props.project].filename2} />
            </div>
        </div>
        <div className="imageFull">
            <BackgroundImage filename={imageList[props.project].filename3} />
        </div>
    </Wrapper>
};

export default mockup;