import React from "react"
import BackgroundImage from './image';
import styled from 'styled-components';
import Container from './container';
import AnimatedText from '../components/animatedText';

const Wrapper = styled.div`
margin:auto;
h2{color:#181718;
text-shadow:none;
font-size:4.5vw;
text-align:center;
padding-bottom: 3.5rem;
    }
.mapContainer{
    width: 100%;
    min-width: 65vw;
}
@media(max-width:1024px){
    .mapContainer{
    min-width: 90vw;
}
}

@media(max-width:768px){
h2{
font-size:6vw;
}
}
@media(max-width:512px){
h2{
font-size:8.5vw;
}}

`


const Map = (props) => {

    return <Container>
        <Wrapper>
            <AnimatedText tag="h2">The Map of the Experience</AnimatedText>
            <div className="mapContainer"> <BackgroundImage filename={props.filename} />
            </div>
        </Wrapper>
    </Container>

};

export default Map;