import React from 'react';
import Container from './container';
import styled from 'styled-components';
import AnimatedText from '../components/animatedText';
const Wrapper = styled.div`

display:flex;
justifyContent:center;

@media (min-width:1024px){
    min-height:100vh;
    align-items:center;
}

p{
    font-size: 
}
`

const description = (props) => {
    const descriptionList = {
        insights: {
            1: "In this project there are fundamental pieces of 20th century design selected by Pierpaolo Piccioli as icons and expression of his personal taste",
            2: "Walking through the rooms, patio and pool, reminiscent of the refined and sober aesthetic Valentino Boutiques are revered for, users can interact with Valentino items that will then be available for purchase directly on the platform with a series of surprises."
        },
        roman: {
            1: "The navigation ushers visitors through additional rooms in which Valentino is in conversation with art and the City of Rome.",
            2: "Interact with the objects in the different rooms dedicated to the Roman Palazzo inspirations and the Maison’s latest artistic collaborations."
        }
    }

    return <Container>
        <Wrapper>
            <AnimatedText tag="paragraph">{descriptionList[props.project][props.paragraph]}</AnimatedText>
        </Wrapper>
    </Container>
};

export default description;