import React from 'react';
import Container from './container';
import Image from './image';
import LogoValentino from '../assets/LogoValentino.svg';
import VideoMockup from "../components/videoMockup";
import AnimatedText from '../components/animatedText';

const Sheet = (props) => {

    const sheetList = {
        insights: {
            intro: "Regarded a middle ground between a videogame and a digital art piece, Valentino Insights is a continuously updated platform on Valentino website, a universal window to discover news and to experience personal activities dedicated to the Valentino Community. The exploration of the virtual villa is guided by notes that indicate on which object to linger, with specific elements for the user to interact with, images to be clicked on and secret spaces that bring users to unveiling clues.",
            title: "Valentino Insights",
            mockup: ["The Valentino Insights home also features a variety of voicemails, videos, a musical selection originating from a vintage record player, extreme scale Valentino accessories that become surreal furniture pieces, walk in closets, exclusive events, and much more."],
            filename: "valentinoinsights_cover.jpg",
            video: "iphoneinsights",
            link: "https://www.vertual.tv/reel-valentino-insights/"
        },
        roman: {
            title: "Roman Palazzo Art Residency",
            intro: "The codes of the Maison, translated into the world of the Roman Palazzo. For its latest iteration, Valentino’s virtual space presents an innovative exhibition of digital NFT works. A series of irreplaceable creations gives life to an exploration of the Maison’s artistic attitude and profound Roman identity.",
            mockup: ["A hidden room will be revealed letting the users to explore the house, collecting the stud users will have access to the secret room, unlocking exclusive contents."],
            filename: "romanedition_cover.jpg",
            video: "iphoneinsightsroman",
            link: "https://www.vertual.tv/reel-valentino-insights-roman-edition/"
        }
    }



    const intro = (
        <>
            <div className="list">
                <Image filename={sheetList[props.project].filename} />
                <div className="logo">
                    <LogoValentino />
                </div>
            </div>
            <div className="description">
                <AnimatedText tag="h2">{sheetList[props.project].title}</AnimatedText>
                <AnimatedText tag="p">{sheetList[props.project][props.position]}</AnimatedText>
                <AnimatedText tag="h4"><a href={sheetList[props.project].link} target="_blank" rel="noreferrer">Watch the Reel</a></AnimatedText>

                {/* <div onClick={props.toggleModal}><AnimatedText tag="h4" > Watch the Reel</AnimatedText></div> */}
            </div>
        </>)

    const mockup = (
        <>
            <div className="list">
                <VideoMockup video={sheetList[props.project].video} />
            </div>
            <div className="description">
                <AnimatedText tag="paragraph">{sheetList[props.project][props.position][0]}</AnimatedText>
                <AnimatedText tag="paragraph">{sheetList[props.project][props.position][1]}</AnimatedText>
            </div>
        </>)


    return <>
        <Container noBottom direction={props.direction} position={props.position}>
            {props.position === "intro" ? intro : mockup}
        </Container>
    </>
};

export default Sheet;